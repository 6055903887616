import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { BlogPost } from '../../models/blog-post';
import '../../pages/id-scanning-blog/articlestyle.css';

interface BlogHeroProps {
    blogMeta: BlogPost[];
}

const BlogHero: FC<BlogHeroProps> = (props) => {

        return (
            <div className="article-container">
                <div className="blog-hero-container mt-lg">
                    <Img fluid={props.blogMeta[0].image} />
                </div>
                {/* <div className="font-heading mt-8 text-mh tab:text-lh">
                    {props.blogMeta[0].heading}
                </div>
                <div className="text-sb tab:text-mb mt-6">
                    {props.blogMeta[0].description}
                </div> */}
            </div>
        )
    }

export default BlogHero;
