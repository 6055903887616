import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BlogHero from '../../components/sections/blog-hero';
import { BlogPost } from '../../models/blog-post';
import SEO from '../../components/seo';
import { gaButtonEvent } from '../../services/visitor-info';
import './articlestyle.css';

const TemplateBlog = () => {
  const data = useStaticQuery(graphql`
    query {
      facialRecHero: file(relativePath: { eq: "blog/facial-recognition.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const blogHeroPost: BlogPost[] = [
    {
      heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostr',
      miniDescription: 'This should really only be about 5 words',
      image: data.facialRecHero.childImageSharp.fluid,
      link: '/get-pricing',
    },
  ];

  return (
    <div>
      <SEO
        title="How Accurate is Facial Recognition?"
        description="Facial recognition is becoming more common for identifying individuals but it's accuracy is still evolving."
        socialImg="patronscan-social-blog-2.jpg"
      />
      <div className="flex justify-center mx-sm mb-sm mt-top lap:mx-md mb-md mt-toplg desk:mx-lg mb-lg mt-toplg">
        <BlogHero blogMeta={blogHeroPost}></BlogHero>
      </div>
      <div className="flex justify-center">
        <div className="mx-sm mb-sm mt-top lap:mx-md mb-md mt-toplg desk:mx-lg mb-lg mt-toplg article-container">
          <h2 className="article-heading font-heading text-sh lap:text-mh mb-6">
            How Accurate is Facial Recognition – and Can It Be Trusted for ID Verification?
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Facial recognition software has progressed considerably over the last five years.{' '}
            <a
              className="text-blue-darker"
              href="https://pages.nist.gov/frvt/reports/1N/frvt_1N_report.pdf"
            >
              {' '}
              A recent study published by NIST{' '}
            </a>{' '}
            (National Institute of Standards in Technology) found substantial improvements in these
            technologies since 2014. Overall failures in matching faces from their database were 4%
            in 2014 whereas the best facial recognition algorithms were attaining failure rates of
            under 0.10% in 2020. [1][2]
            <br></br>
            <br></br>
            Governments have been experimenting with facial recognition for law enforcement, border
            security and even public transportation. But as the deployment of these technologies
            continues to grow, so does the controversy.
            <br></br>
            <br></br>
            Notably, privacy advocates have cited legitimate concerns on how these technologies
            train their algorithms including the use of images made available through social media
            platforms. Civil liberty groups have raised concerns around the inaccuracy of these
            technologies and how they risk serious civil rights violations.
            <br></br>
            <br></br>
            In theory, readily available facial recognition technologies could complement ID
            scanning by comparing the profile image captured from a scanned identity document with a
            live photo captured at the time of the scan. The ID scanning system could make then make
            a determination of whether a legitimate ID belonged to the individual presenting it.
            <br></br>
            <br></br>
            At{' '}
            <a
              href="https://www.patronscan.com"
              onClick={() => {
                gaButtonEvent('Patronscan', 'FacialRecPost:Patronscan');
              }}
            >
              Patronscan
            </a>
            , we’ve certainly considered this as a possibility and have been closely following
            facial recognition developments for the last 15 years. But after completing our most
            recent review, we can't trust these technologies for accurate ID verification. As it
            turns out, humans are still far better than machines when matching faces in real-world
            circumstances.
            <br></br>
            <br></br>
            You don’t need to look far to find evidence in some of the more high-profile facial
            recognition flops – the ACLU discovered that 28 members of congress were incorrectly
            matched with mugshots from a database of 25,000 using Amazon’s facial recognition
            technology in 2018.[3]
            <br></br>
            <br></br>
            Understanding how facial recognition works is the first step in discovering why the
            technology isn’t suitable for most ID verification cases.
            <br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-heading text-sh lap:text-mh mb-6">
            How does Facial Recognition Work?
          </h2>
          <p className="article-body text-sb lap:text-mb">
            Facial recognition software varies across algorithms but generally works in the same
            fashion.
            <br></br>
            <br></br>
            Most facial recognition algorithms compare the distances between the facial features on
            a given photo or video capture against record(s) of the individual on file. The software
            traces lines between facial features to create a geometric pattern of the captured face.
            <br></br>
            <br></br>
            This pattern is subsequently matched against the reference image to determine a likeness
            score. The higher the score, the more likely the individual is a match. If the likeness
            score passes a set threshold, a positive match is returned.
            <br></br>
            <br></br>
            These algorithms are born by training off of datasets made available to the developers –
            for example, publicly available mugshots. Once they are deployed to production, these
            facial recognition technologies leverage deep learning to continuously improve accuracy.
            <br></br>
            <br></br>
            This approach tends to lead to matching bias based on available data. Certain
            individuals, notably darker-skinned faces and women, experience significantly higher
            false matches.
            <br></br>
            <br></br>
            Our own testing of commercially available technologies proved similar results. Women and
            darker-skinned individuals had matching scores that were highly inaccurate and in one of
            our negative tests, a woman matched higher with a man than her own license photo.
            <br></br>
            <br></br>
            Even though our own recent testing often provided comical results, facial recognition
            can work really well in some cases. Individuals unlock their phones using their face and
            banks trust the same technology to allow users to sign into their online banking
            accounts.
            <br></br>
            <br></br>
            Surely, the banks wouldn’t trust a technology with the accuracy rates we experienced
            when matching live photos with license photos. So, what’s the difference? Well it boils
            down to the use case and how you’re able to train your device to recognize you face.
            <br></br>
            <br></br>
          </p>
          <h2 className="article-heading font-heading text-sh lap:text-mh mb-6">
            Just how Accurate is Facial Recognition?
          </h2>
          <p className="article-body text-sb lap:text-mb">
            For most ID verification cases, not accurate enough. There are a few factors to take
            into consideration.
            <br></br>
            <br></br>
            One of the first is the technologies are used for matching in off-the-shelf products.
            There is a trade off between accuracy, speed and cost which drive the economics in
            determining which algorithm should be used in a commercial product.
            <br></br>
            <br></br>
            In NIST’s recent study on leading facial recognition algorithms not only found a
            significant range in effectiveness but the institute also cautioned “[t]he large
            accuracy range is consistent with the buyer-beware maxim….” [3]
            <br></br>
            <br></br>
            Most commercially available products using facial recognition for ID verification have
            selected algorithms that favour speed and cost over accuracy.
            <br></br>
            <br></br>
            More importantly, all of these algorithms’ technologies work best under ideal conditions
            where lighting is controlled, facial features are unobscured, and the reference image is
            recent. In the real world, deployments tend to have significantly lower accuracy rates
            than the experiments conducted under a controlled environment.
            <br></br>
            <br></br>
            Facial recognition does tend to work well outside of these constraints.
            <br></br>
            <br></br>
            Leveraging facial recognition to unlock your phone is made possible by training the
            operating system when setting up your device with multiple facial captures. This
            significantly increases the accuracy of detecting your face – so long as you’re not
            wearing a mask or sunglasses.
          </p>
          <br></br>
          <br></br>
          <h2 className="article-heading font-heading text-sh lap:text-mh mb-6">
            Facial Recognition and ID Scanning
          </h2>
          <p className="article-body text-sb lap:text-mb">
            The major challenge in using facial recognition to tie an identity document to an
            individual is there is only one reference image to compare against. What’s even more
            problematic is this image could have been taken 5 or even 10 years ago.
            <br></br>
            <br></br>
            In these cases, the accuracy rates tend to drop significantly. One reference image is
            simply not enough to create likeness scores that would be deemed acceptable. This is
            highly problematic when incorporating facial recognition into identity verification and
            provides false senses of security rather than accuracy.
            <br></br>
            <br></br>
            Simply put, humans are currently much better than machines at matching faces when
            checking an identity document.
            <br></br>
            <br></br>
            If we were to deploy facial recognition into{' '}
            <a className="text-blue-darker" href="https://www.patronscan.com">
              Patronscan
            </a>
            , we would be going against what we whole-heartedly believe in – building trusted
            relationships through <em>accurate</em> identity verification.
            <br></br>
            <br></br>
            [1] (2018) NIST Evaluation Shows Advance in Face Recognition Software’s Capabilities{' '}
            <a
              onClick={() => gaButtonEvent('NistStudy', 'FacialRecPost:NistStudy')}
              className="text-blue-darker"
              href="https://www.nist.gov/news-events/news/2018/11/nist-evaluation-shows-advance-face-recognition-softwares-capabilities"
            >
              https://www.nist.gov/news-events/news/2018/11/nist-evaluation-shows-advance-face-recognition-softwares-capabilities
            </a>
            <br></br>
            <br></br>
            [2] [4] Grother, F., Ngan, M., and Hanaoka, K., (2020). FRVT Part 2: Identification{' '}
            <a
              className="text-blue-darker"
              href="https://pages.nist.gov/frvt/reports/1N/frvt_1N_report.pdf"
            >
              https://pages.nist.gov/frvt/reports/1N/frvt_1N_report.pdf
            </a>
            <br></br>
            <br></br>
            [3] Snow, J., (2018). Amazon’s Face Recognition Falsely Matched 28 Members of Congress
            With Mugshots{' '}
            <a
              className="text-blue-darker"
              href="https://www.aclu.org/blog/privacy-technology/surveillance-technologies/amazons-face-recognition-falsely-matched-28"
            >
              {' '}
              https://www.aclu.org/blog/privacy-technology/surveillance-technologies/amazons-face-recognition-falsely-matched-28
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TemplateBlog;
